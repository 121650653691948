// Quelle: https://jsfiddle.net/polaszk/1oyfxoor/
class Anzahl {
    constructor(inputContainer, btnPlusSelector, btnMinusSelector, anzahlInputSelector) {
        this.inputContainer = inputContainer;
        this.btnPlusSelector = btnPlusSelector;
        this.btnMinusSelector = btnMinusSelector;
        this.anzahlInputSelector = anzahlInputSelector;
    }

    initialisierungInputGroups() {
        this.inputGroups = document.querySelectorAll(this.inputContainer);

        for (const inputGroup of this.inputGroups) {
            const plusBtn = inputGroup.querySelector(this.btnPlusSelector);
            const minusBtn = inputGroup.querySelector(this.btnMinusSelector);
            const anzahlInput = inputGroup.querySelector(this.anzahlInputSelector);

            plusBtn.addEventListener('click', (e) => this.handlePlusClick(e));
            minusBtn.addEventListener('click', (e) => this.handleMinusClick(e));
            anzahlInput.addEventListener('change', (e) => this.handleChange(e));
        }
    }

    handlePlusClick(event) {
        event.preventDefault();
        const fieldName = event.target.dataset.field;
        const parent = event.target.closest('div');
        const currentVal = parseInt(parent.querySelector(`input[name="${fieldName}"]`).value, 10);

        if (!isNaN(currentVal)) {
            parent.querySelector(`input[name="${fieldName}"]`).value = currentVal + 1;
        } else {
            parent.querySelector(`input[name="${fieldName}"]`).value = 1;
        }
        this.#anzahlEventAusloesen();
    }

    handleMinusClick(event) {
        event.preventDefault();
        const fieldName = event.target.dataset.field;
        const parent = event.target.closest('div');
        const currentVal = parseInt(parent.querySelector(`input[name="${fieldName}"]`).value, 10);

        if (Number.isInteger(currentVal) && currentVal > 1) {
            parent.querySelector(`input[name="${fieldName}"]`).value = currentVal - 1;
        } else {
            parent.querySelector(`input[name="${fieldName}"]`).value = 1;
        }
        this.#anzahlEventAusloesen();
    }
    
    handleChange(event) {
        event.preventDefault();
        const currentVal = parseInt(event.target.value, 10);
        if (isNaN(currentVal) || currentVal < 1) {
            event.target.value = 1;
        }
        this.#anzahlEventAusloesen();
    }

    #anzahlEventAusloesen() {
        // Event erstellen
        let event = new CustomEvent('warenkorbAnzahlEvent');
        // dispatch the event
        document.dispatchEvent(event);
    }
}